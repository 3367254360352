<template>
  <div>
    <v-card flat>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">TO BE APPROVE REQUEST FUND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Date Requested</th>
              <th class="text-center text-uppercase">Branch</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Remarks</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in requested_for_approval" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ date_format(item.created_at).format('MMMM DD, YYYY HH:mm:ss') }}
              </td>
              <td class="text-center">
                {{ item.branch.branch_code}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount)}}
              </td>
              <td class="text-center">
                {{ item.remarks}}
              </td>
              <td class="text-center">
                <v-icon
                  v-if="!is_deleting"
                  class="mr-2"
                  color="success"
                  @click="update_datas(requested_for_approval[requested_for_approval.map(function(x) {return x.id; }).indexOf(item.id)],true)"
                >
                  {{icons.mdiCheck}}
                </v-icon>
                <v-icon
                  v-if="!is_deleting"
                  class="mr-2"
                  color="error"
                  @click="update_datas(requested_for_approval[requested_for_approval.map(function(x) {return x.id; }).indexOf(item.id)],false)"
                >
                  {{icons.mdiCancel}}
                </v-icon>
                <v-progress-circular color="info" indeterminate
                                     v-else></v-progress-circular>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiInformationOutline,
    mdiAccountCashOutline,
    mdiCash,
    mdiCellphone,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline,
    mdiCashRefund,
    mdiShieldAccount,
    mdiGoogleCirclesCommunities,
    mdiBallotOutline,
    mdiAccountCash,
    mdiCheck,
    mdiCancel,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from "moment";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiAccountCash,
          mdiCheck,
          mdiCancel,
        },
      }
    },
    data() {
      return {
        id: 0,
        id_no: '',
        name: '',

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],

        requested_for_approval: [],
        is_deleting: false,
      }
    },
    created() {
      this.initialize_data()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('fund', ['get_request_fund_not_yet_approved', 'approved_or_deny_request']),
      ...mapActions('system_data', ['change_snackbar']),
      initialize_data() {
        this.get_request_fund_not_yet_approved().then(response => {
          this.requested_for_approval = response.data
        })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.is_deleting = false
        this.initialize_data()
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      update_datas(datas, is_data) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', datas.id)
        data.append('approved', is_data)
        data.append('approver', this.user_id)
        this.approved_or_deny_request(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      }
    },
  }
</script>
